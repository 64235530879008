var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "form", attrs: { "lazy-validation": "", disabled: _vm.loading } },
    [
      _c("v-text-field", {
        attrs: {
          outlined: "",
          label: "Paste URL here",
          type: "url",
          rules: _vm.urlRules,
          required: "",
        },
        model: {
          value: _vm.url,
          callback: function ($$v) {
            _vm.url = $$v
          },
          expression: "url",
        },
      }),
      _vm.success
        ? _c(
            "v-btn",
            { attrs: { color: "success" } },
            [_c("v-icon", [_vm._v("mdi-check")])],
            1
          )
        : _c(
            "v-btn",
            {
              attrs: { loading: _vm.loading, color: "primary" },
              on: { click: _vm.upload },
            },
            [_vm._v("Upload")]
          ),
      _vm.error
        ? _c(
            "v-alert",
            { staticClass: "mt-4", attrs: { text: "", type: "error" } },
            [_vm._v(_vm._s(_vm.error))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }